import classnames from 'classnames'
import moment from 'moment'

import { getLocalDate } from './'

export function getTotalOdds(odds, settings) {
  let total = odds.reduce((quote, o) => quote *= o.odd.cotacao, 1)
  total = total > 1 ? total : 0
  const max = settings.multiplicador_maximo
  return Math.min(total, max)
}

export function getBetWinnings(amount, oddsTotal, settings) {
  const total = oddsTotal * +(('' + amount).replace(',', '.'))
  const max = settings.premio_maximo
  return Math.min(total, max)
}

export function getItemStatus(status) {
  if (status == 1) return 'Aguardando'
  if (status == 2) return 'Acertou'
  if (status == 3) return 'Perdeu'
  if (status == 4) return 'Cancelado'
  if (status == 5) return 'Anulado'
  if (status == 6) return 'Em Análise'
  if (status == 7) return 'Encerrado'
  return null
}

export function generateTicketHtml(data, settings) {
  const isDarkTheme = settings.tema_padrao === 1

  const ticketContent = data.ITENS.map((item) => {
    const renderResult = (result) => `
      <div class="ticket-row-item border-top pt-2 mt-2">
        <div class="ticket-col-item text-left">Placar</div>
        <div class="ticket-col-item text-right">1T (${result.casa_1t} - ${result.fora_1t}) / 2T (${result.casa_2t} - ${result.fora_2t})</div>
      </div>
    `

    return `
      <div class="ticket-row-item ticket-header">
        <div class="ticket-col-item text-left">${item.PAIS} - ${item.CAMPEONATO}</div>
        <div class="ticket-col-item ticket-col-item-small text-right">${item.AO_VIVO ? 'Ao vivo' : 'Pré-jogo'}</div>
      </div>
      <div class="ticket-row-item">
        <div class="ticket-col-item text-left">${item.MANDANTE}</div>
        <div class="ticket-col-item text-right">${item.DATA} - ${item.HORA}</div>
      </div>
      <div class="ticket-row-item">
        <div class="ticket-col-item text-left">${item.VISITANTE}</div>
        <div class="ticket-col-item text-right">${item.COTA.toFixed(2)}</div>
      </div>
      ${item.RESULTADO != null ? renderResult(item.RESULTADO) : ''}
      <div class="ticket-row-item border-top pt-2 mt-2">
        <div class="ticket-col-item text-left">Modalidade</div>
        <div class="ticket-col-item text-right">${item.ESPORTE}</div>
      </div>
      <div class="ticket-row-item">
        <div class="ticket-col-item text-left">Situação</div>
        <div class="${classnames('ticket-col-item text-right', { 'text-success': item.STATUS == 2, 'text-danger': [3, 4, 5].includes(item.STATUS), 'text-warning': item.STATUS == 6 })}">
          ${getItemStatus(item.STATUS)}
        </div>
      </div>
      <div class="ticket-row-item ticket-option">
        <div class="w-100 text-center">${item.APOSTA}</div>
      </div>
    `
  }).join('')

  let ctaLive = ''
  if (data.BILHETE.cod_status == 1 && settings.modulo_bilhete_ao_vivo) {
    ctaLive = `<div class="row mb-3">
      <div class="col">
        <a href="/ticket/live?i=${data.BILHETE.cod}" class="btn btn-danger btn-block text-white">
          <span class="blink-animation">
            <i class="fas fa-signal-stream m-2"></i>
            Acompanhe ao vivo
          </span>
        </a>
      </div>
    </div>`
  }

  let sorteios = ''
  data.SORTEIOS?.map((sorteio) => {
    const data = getLocalDate(sorteio.data_sorteio).format('DD/MM/yyyy')
    const hora = getLocalDate(sorteio.data_sorteio).format('HH:mm')

    if (sorteio.ganhador === null) {
      sorteios += `<div role="alert" class="fade alert alert-info show">
        Este bilhete está concorrendo a <b>${sorteio.premio}</b> a ser sorteado no dia <b>${data} às ${hora}</b>. Verifique sua aposta após a data marcada.
      </div>`
    } else if (sorteio.ganhador) {
      sorteios += `<div role="alert" class="fade alert alert-success show">
        Este bilhete foi sorteado com <b>${sorteio.premio}</b> durante sorteio realizado no dia <b>${data} às ${hora}</b>. ${sorteio.premio_entregue ? '' : 'Entre em contato com nosso atendimento para resgatar seu prêmio.'}
      </div>`
    } else {
      sorteios += `<div role="alert" class="fade alert alert-danger show">
        Este bilhete participou do sorteio de ${sorteio.premio} realizado no dia ${data} às ${hora} porém não foi sorteado. Agradecemos a sua participação!
      </div>`
    }
  })

  return `
    <div class="ticket">
      <div class="logo">
          <img src="${settings.logo || `/images/logo${!isDarkTheme ? '-dark' : ''}.png`}" width="200">
      </div>
      ${sorteios}
      ${ctaLive}
      <div class="ticket-row rounded-top">
          <div class="ticket-col text-left">Código</div>
          <div class="ticket-col text-right text-uppercase">${data.BILHETE.cod}</div>
      </div>
      <div class="ticket-row">
          <div class="ticket-col text-left">Situação</div>
          <div class="${classnames('ticket-col text-right', { 'text-success': [2, 21].includes(data.BILHETE.cod_status), 'text-danger': [3, 4].includes(data.BILHETE.cod_status) })}">${data.BILHETE.status}</div>
      </div>
      <div class="ticket-row">
          <div class="ticket-col text-left">Apostador</div>
          <div class="ticket-col text-right">${data.BILHETE.nome}</div>
      </div>
      <div class="ticket-row">
          <div class="ticket-col text-left">Valor Apostado</div>
          <div class="ticket-col text-right">R$ ${data.BILHETE.valor_aposta}</div>
      </div>
      ${data.BILHETE.cashback ? `
        <div class="ticket-row">
          <div class="ticket-col text-left">Cashback</div>
          <div class="ticket-col text-right">R$ ${data.BILHETE.cashback}</div>
        </div>`
        : ''
      }
      <div class="ticket-row">
          <div class="ticket-col text-left">Prêmio Estimado</div>
          <div class="ticket-col text-right">R$ ${data.BILHETE.retorno}</div>
      </div>
      ${data.BILHETE.retorno_real ? `
        <div class="ticket-row">
          <div class="ticket-col text-left">Cambista Paga</div>
          <div class="ticket-col text-right">R$ ${data.BILHETE.retorno_real}</div>
        </div>`
        : ''
      }
      <div class="ticket-row rounded-bottom">
          <div class="ticket-col text-left">Horário da Aposta</div>
          <div class="ticket-col text-right">${data.BILHETE.data} - ${data.BILHETE.hora}</div>
      </div>
      <div class="ticket-separator">
          <h3 class="text-center">Palpites (${data.ITENS.length})</h3>
      </div>
      <div class="ticket-content">
        ${ticketContent}
      </div>
    </div>
  `
}

export function generatePrintContent(BILHETE, ITENS, settings) {
  return (
    `<html>
      <head>
        <style>
          *{margin:0;padding:0}
          body{font-size:12px;font-family:"Andale Mono",AndaleMono,monospace}
          .print-ticket{margin:1em .2em}
          .print-ticket .site{font-size:24px;font-weight:700;text-align:center;text-transform:uppercase}
          .print-ticket .site img{filter: brightness(0%)}
          .print-ticket .content{width:100%}
          .print-ticket .content .item{display:flex;flex-wrap:wrap;padding:.2em 0;border-bottom:1px solid #000}
          .print-ticket .content .no-border{padding:0;border-bottom:0}
          .print-ticket .content .item .col{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;-webkit-box-flex:1;flex-grow:1;max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
          .print-ticket .separator{margin:1em 0;font-size:15;font-weight:700;text-align:center;text-transform:uppercase}
          .bg{color:#fff;padding:.2em;background-color:#000;color-adjust:exact;-webkit-print-color-adjust: exact;}
          .text-left{text-align:left}
          .text-right{text-align:right}
          .text-center{text-align:center}
          .text-uppercase{text-transform:uppercase}
          .mensagem{padding:1em 0;text-align:center}
          .border-top{padding:1em 0;border-top:1px solid #000}
        </style>
      </head>
      <body>
        <div class="print-ticket">
          <div class="site">
            ${settings.nome}
          </div>
          <div class="content">
            <div class="item">
              <div class="col text-left text-uppercase">
                Código
              </div>
              <div class="col text-right text-uppercase">
                ${BILHETE.cod}
              </div>
            </div>
            <div class="item">
              <div class="col text-left text-uppercase">
                Apostador
              </div>
              <div class="col text-right text-uppercase">
                ${BILHETE.nome}
              </div>
            </div>
            ${BILHETE.cambista && `<div class="item">
              <div class="col text-left text-uppercase">
                Cambista
              </div>
              <div class="col text-right text-uppercase">
                ${BILHETE.cambista}
              </div>
            </div>`}
            <div class="item">
              <div class="col text-left text-uppercase">
                Valor Apostado
              </div>
              <div class="col text-right text-uppercase">
                R$ ${BILHETE.valor_aposta}
              </div>
            </div>
            <div class="item">
              <div class="col text-left text-uppercase">
                Retorno
              </div>
              <div class="col text-right text-uppercase">
                R$ ${BILHETE.retorno}
              </div>
            </div>
            <div class="item">
              <div class="col text-left text-uppercase">
                Horario
              </div>
              <div class="col text-right text-uppercase">
                ${BILHETE.data} ${BILHETE.hora}
              </div>
            </div>
          </div>
          <div class="separator">
            ${ITENS.length > 1 ? 'Meus palpites' : 'Meu palpite'}
          </div>
          <div class="content">
            ${ITENS.map((item) => `
              <div class="item">
                <div class="col text-left text-uppercase">
                  ${item.PAIS} - ${item.CAMPEONATO}
                </div>
                <div class="col text-right text-uppercase">
                  ${item.AO_VIVO ? 'Ao vivo' : 'Pré-jogo'}
                </div>
              </div>
              <div class="item no-border">
                <div class="col text-left text-uppercase">
                  ${item.MANDANTE}
                </div>
                <div class="col text-right text-uppercase">
                  ${moment(item.DATA_HORA).format('DD/MM - HH:mm')}
                </div>
              </div>
              <div class="item">
                <div class="col text-left text-uppercase">
                  ${item.VISITANTE}
                </div>
                <div class="col text-right text-uppercase">
                </div>
              </div>
              <div class="item no-border">
                <div class="col text-left text-uppercase">
                  Modalidade
                </div>
                <div class="col text-right text-uppercase">
                  ${item.ESPORTE}
                </div>
              </div>
              <div class="item no-border">
                <div class="col text-left text-uppercase">
                  Cotação
                </div>
                <div class="col text-right text-uppercase">
                  ${item.COTA}
                </div>
              </div>
              <div class="item">
                <div class="col text-center text-uppercase">
                  ${item.APOSTA}
                </div>
              </div>
            `).join('')}
            <div class="item no-border">
              <div class="col text-left text-uppercase">
                Contato
              </div>
              <div class="col text-right text-uppercase">
                ${settings.telefone}
              </div>
            </div>
            <div class="item">
              <div class="col text-left text-uppercase">
                Qtd. palpites
              </div>
              <div class="col text-right text-uppercase">
                ${ITENS.length}
              </div>
            </div>
          </div>
          <div class="mensagem">
            ${BILHETE.mensagem}
          </div>
        </div>
      </body>
    </html>`
  )
}
